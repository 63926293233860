import React from 'react';
import {Link} from "react-router-dom";

export default ({match: {url}}) =>
    <div className="uk-hidden@l">
        <nav className="uk-navbar-container"
             data-uk-navbar
             data-uk-height-match=">div"
        >
            <div className="uk-navbar-center">
                <Link to="/"
                      className="uk-navbar-item uk-logo"
                >
                    <img src="/assets/logo.svg"
                         alt="Tanzschule Fischer"
                    />
                </Link>
            </div>
            <div className="uk-navbar-right">
                <button className="uk-navbar-toggle uk-button uk-button-link"
                        data-uk-toggle="#mobile"
                >
                    <div data-uk-navbar-toggle-icon
                         className="uk-navbar-toggle-icon uk-icon"
                         data-uk-icon="icon: menu; ratio:1.5"
                    />
                </button>
            </div>
        </nav>
        <div id="mobile"
             data-uk-offcanvas="mode:push; overlay:true; flip:true"
             className="uk-offcanvas"
        >
            <div className="uk-offcanvas-bar uk-offcanvas-bar-animation">
                <button className="uk-offcanvas-close uk-close uk-icon"
                        type="button"
                        data-uk-close="ratio:1.5"
                />
                <div className="uk-child-width-1-1 uk-grid uk-grid-stack"
                     data-uk-grid
                >
                    <div>
                        <div className="uk-panel">
                            <ul className="uk-nav uk-nav-default">
                                <li className={url === '/' ? 'uk-active' : ''}>
                                    <Link to="/">Home</Link>
                                </li>
                                <li className={url === '/courses' ? 'uk-active' : ''}>
                                    <Link to="/courses">Kurse</Link>
                                </li>
                                <li className={url === '/coaches' ? 'uk-active' : ''}>
                                    <Link to="/coaches">Team</Link>
                                </li>
                                <li className={url === '/about' ? 'uk-active' : ''}>
                                    <Link to="/about">Über uns</Link>
                                </li>
                                <li className={url === '/schedules' ? 'uk-active' : ''}>
                                    <Link to="/schedules">Termine</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>