import React, {Component} from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import ScrollToTop from "./components/_ScrollToTop";

import Home from './pages/Home';

class App extends Component {
    render() {
        return <BrowserRouter>
            <ScrollToTop>
                <Route exact path="/" render={props => <Home {...props}/>}/>
                {/*<Route path="/about" render={props => <About {...props}/>}/>*/}
                {/*<Route path="/contact" render={props => <Contact {...props}/>}/>*/}
                {/*<Route path="/imprint" render={props => <Imprint {...props}/>}/>*/}
                {/*<Route path="/terms" render={props => <Terms {...props}/>}/>*/}
                {/*<Route path="/privacy" render={props => <Privacy {...props}/>}/>*/}
            </ScrollToTop>
        </BrowserRouter>
    }
}

export default App;
