import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import UiKit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import './App.scss';

UiKit.use(Icons);

ReactDOM.render(<App/>, document.getElementById('root'));
