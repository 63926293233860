import React, {Fragment} from 'react';
import Navbar from './Navbar'
import Mobile from './Mobile'
import Footer from './Footer'

export default props =>
    <Fragment>
        <Navbar {...props}/>
        <Mobile {...props}/>
        {props.children}
        <Footer/>
    </Fragment>