import React from 'react';

export default () =>
    <div className="uk-section-default uk-section uk-padding-remove-top"
         data-uk-scrollspy="target: [uk-scrollspy-class]; cls: uk-animation-slide-bottom-medium; delay: 200;"
    >
        <div className="uk-margin-large uk-container uk-container-large">
            <div className="uk-flex-bottom"
                 data-uk-grid
            >
                <div className="uk-width-2-3@m uk-first-column">
                    <h2 className="uk-margin-remove-vertical uk-width-xlarge uk-h1"
                        data-uk-scrollspy-class
                    >
                        Bervorstehende Kurse
                    </h2>
                </div>
                <div className="uk-width-expand@m">
                    <div className="uk-margin uk-text-right@m"
                         data-uk-scrollspy-class
                    >
                        <button className="uk-button uk-button-primary uk-button-large">
                            Alle Termine
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className="uk-grid-margin uk-grid-stack"
             data-uk-grid
        >
            <div className="uk-width-1-1@m uk-first-column">
                <div className="uk-margin uk-text-left uk-slider uk-slider-container"
                     data-uk-slider="sets: true"
                     data-uk-scrollspy-class
                >
                    <div className="uk-position-relative uk-visible-toggle">
                        <ul className="uk-slider-items uk-grid">
                            <li className="uk-width-4-5 uk-width-1-2@s uk-width-1-3@m uk-width-1-4@l">
                                <div
                                    className="uk-card-media uk-card-default uk-card-small"
                                    tabIndex="0"
                                >
                                    <div className="uk-card-media-top">
                                        <div
                                            className="uk-overflow-hidden uk-position-relative uk-light uk-height-medium">
                                            <img data-uk-cover
                                                 alt=""
                                                 data-uk-img="target: !.uk-slider-items"
                                                 data-src="https://images.unsplash.com/photo-1502519144081-acca18599776?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80"
                                            />
                                            <div
                                                className="uk-overlay uk-overlay-default uk-background-primary uk-padding-small uk-position-bottom">
                                                <h3 className="uk-margin uk-h4">
                                                    Hip Hop kids
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-card-body">
                                        <div className="uk-grid-divider uk-child-width-1-2 uk-grid-small"
                                             data-uk-grid>
                                            <div className="uk-text-right">
                                                <div>Heute:</div>
                                                <div>Montag:</div>
                                            </div>
                                            <div className="uk-text-left">
                                                <div className="uk-flex uk-flex-middle">
                                                    <i className="uk-text-primary"
                                                       data-uk-icon="icon: clock; ratio: 0.85"
                                                    /> 13:00
                                                </div>
                                                <div className="uk-flex uk-flex-middle">
                                                    <i className="uk-text-primary"
                                                       data-uk-icon="icon: clock; ratio: 0.85"
                                                    /> 17:15
                                                </div>
                                            </div>
                                        </div>
                                        <p className="uk-text-right">
                                            <button className="uk-button uk-button-text">weitere Termine</button>
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li className="uk-width-4-5 uk-width-1-2@s uk-width-1-3@m uk-width-1-4@l">
                                <div
                                    className="uk-card-media uk-card-default uk-card-small"
                                    tabIndex="0"
                                >
                                    <div className="uk-card-media-top">
                                        <div
                                            className="uk-overflow-hidden uk-position-relative uk-light uk-height-medium">
                                            <img data-uk-cover
                                                 alt=""
                                                 data-uk-img="target: !.uk-slider-items"
                                                 data-src="https://images.unsplash.com/photo-1512264815082-9999c2d4894d?ixlib=rb-1.2.1&auto=format&fit=crop&w=2223&q=80"
                                            />
                                            <div
                                                className="uk-overlay uk-overlay-default uk-background-primary uk-padding-small uk-position-bottom">
                                                <h3 className="uk-margin uk-h4">
                                                    Hip Hop Erwachsene
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-card-body">
                                        <div className="uk-grid-divider uk-child-width-1-2 uk-grid-small"
                                             data-uk-grid>
                                            <div className="uk-text-right">
                                                <div>Heute:</div>
                                                <div>Montag:</div>
                                            </div>
                                            <div className="uk-text-left">
                                                <div className="uk-flex uk-flex-middle">
                                                    <i className="uk-text-primary"
                                                       data-uk-icon="icon: clock; ratio: 0.85"
                                                    /> 13:00
                                                </div>
                                                <div className="uk-flex uk-flex-middle">
                                                    <i className="uk-text-primary"
                                                       data-uk-icon="icon: clock; ratio: 0.85"
                                                    /> 17:15
                                                </div>
                                            </div>
                                        </div>
                                        <p className="uk-text-right">
                                            <button className="uk-button uk-button-text">weitere Termine</button>
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li className="uk-width-4-5 uk-width-1-2@s uk-width-1-3@m uk-width-1-4@l">
                                <div
                                    className="uk-card-media uk-card-default uk-card-small"
                                    tabIndex="0"
                                >
                                    <div className="uk-card-media-top">
                                        <div
                                            className="uk-overflow-hidden uk-position-relative uk-light uk-height-medium">
                                            <img data-uk-cover
                                                 alt=""
                                                 data-uk-img="target: !.uk-slider-items"
                                                 data-src="https://images.unsplash.com/photo-1536129808005-fae894214c73?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2095&q=80"
                                            />
                                            <div
                                                className="uk-overlay uk-overlay-default uk-background-primary uk-padding-small uk-position-bottom">
                                                <h3 className="uk-margin uk-h4">
                                                    Latein Erwachsene
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-card-body">
                                        <div className="uk-grid-divider uk-child-width-1-2 uk-grid-small"
                                             data-uk-grid>
                                            <div className="uk-text-right">
                                                <div>Heute:</div>
                                                <div>Montag:</div>
                                            </div>
                                            <div className="uk-text-left">
                                                <div className="uk-flex uk-flex-middle">
                                                    <i className="uk-text-primary"
                                                       data-uk-icon="icon: clock; ratio: 0.85"
                                                    /> 13:00
                                                </div>
                                                <div className="uk-flex uk-flex-middle">
                                                    <i className="uk-text-primary"
                                                       data-uk-icon="icon: clock; ratio: 0.85"
                                                    /> 17:15
                                                </div>
                                            </div>
                                        </div>
                                        <p className="uk-text-right">
                                            <button className="uk-button uk-button-text">weitere Termine</button>
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li className="uk-width-4-5 uk-width-1-2@s uk-width-1-3@m uk-width-1-4@l">
                                <div
                                    className="uk-card-media uk-card-default uk-card-small"
                                    tabIndex="0"
                                >
                                    <div className="uk-card-media-top">
                                        <div
                                            className="uk-overflow-hidden uk-position-relative uk-light uk-height-medium">
                                            <img data-uk-cover
                                                 alt=""
                                                 data-uk-img="target: !.uk-slider-items"
                                                 data-src="https://images.unsplash.com/photo-1504609813442-a8924e83f76e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80"
                                            />
                                            <div
                                                className="uk-overlay uk-overlay-default uk-background-primary uk-padding-small uk-position-bottom">
                                                <h3 className="uk-margin uk-h4">
                                                    Paartanz
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-card-body">
                                        <div className="uk-grid-divider uk-child-width-1-2 uk-grid-small"
                                             data-uk-grid>
                                            <div className="uk-text-right">
                                                <div>Heute:</div>
                                                <div>Montag:</div>
                                            </div>
                                            <div className="uk-text-left">
                                                <div className="uk-flex uk-flex-middle">
                                                    <i className="uk-text-primary"
                                                       data-uk-icon="icon: clock; ratio: 0.85"
                                                    /> 13:00
                                                </div>
                                                <div className="uk-flex uk-flex-middle">
                                                    <i className="uk-text-primary"
                                                       data-uk-icon="icon: clock; ratio: 0.85"
                                                    /> 17:15
                                                </div>
                                            </div>
                                        </div>
                                        <p className="uk-text-right">
                                            <button className="uk-button uk-button-text">weitere Termine</button>
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li className="uk-width-4-5 uk-width-1-2@s uk-width-1-3@m uk-width-1-4@l">
                                <div
                                    className="uk-card-media uk-card-default uk-card-small"
                                    tabIndex="0"
                                >
                                    <div className="uk-card-media-top">
                                        <div
                                            className="uk-overflow-hidden uk-position-relative uk-light uk-height-medium">
                                            <img data-uk-cover
                                                 alt=""
                                                 data-uk-img="target: !.uk-slider-items"
                                                 data-src="https://images.unsplash.com/photo-1504609813442-a8924e83f76e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80"
                                            />
                                            <div
                                                className="uk-overlay uk-overlay-default uk-background-primary uk-padding-small uk-position-bottom">
                                                <h3 className="uk-margin uk-h4">
                                                    Paartanz
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-card-body">
                                        <div className="uk-grid-divider uk-child-width-1-2 uk-grid-small"
                                             data-uk-grid>
                                            <div className="uk-text-right">
                                                <div>Heute:</div>
                                                <div>Montag:</div>
                                            </div>
                                            <div className="uk-text-left">
                                                <div className="uk-flex uk-flex-middle">
                                                    <i className="uk-text-primary"
                                                       data-uk-icon="icon: clock; ratio: 0.85"
                                                    /> 13:00
                                                </div>
                                                <div className="uk-flex uk-flex-middle">
                                                    <i className="uk-text-primary"
                                                       data-uk-icon="icon: clock; ratio: 0.85"
                                                    /> 17:15
                                                </div>
                                            </div>
                                        </div>
                                        <p className="uk-text-right">
                                            <button className="uk-button uk-button-text">weitere Termine</button>
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div className="uk-hidden-hover uk-hidden-touch uk-visible@s">
                            <button
                                className="uk-slidenav-large uk-position-medium uk-position-top-left uk-slidenav-previous uk-icon uk-slidenav"
                                data-uk-slidenav-previous
                                data-uk-slider-item="previous"
                                data-uk-icon="arrow-left"
                                data-ratio="2"
                            />
                            <button
                                className="uk-slidenav-large uk-position-medium uk-position-top-right uk-slidenav-next uk-icon uk-slidenav"
                                data-uk-slidenav-next
                                data-uk-slider-item="next"
                                data-uk-icon="arrow-right"
                                data-ratio="2"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>