import React from 'react';
import {Link} from "react-router-dom";

export default () =>
    <div className="uk-section-default uk-section uk-padding-remove-top">
        <div className="uk-grid-margin uk-grid uk-grid-stack"
             data-uk-grid
        >
            <div className="uk-width-1-1@m uk-first-column">
                <div className="uk-margin uk-slideshow"
                     data-uk-slideshow="animation: fade; autoplay: 1; pauseOnHover: false;">
                    <div className="uk-position-relative">
                        <ul className="uk-slideshow-items"
                            data-uk-height-viewport="offset-top: true; minHeight: 600;"
                        >
                            <li>
                                <img className="uk-cover"
                                     alt=""
                                     data-uk-cover
                                     data-uk-img="target: !.uk-slideshow-items"
                                     data-src="https://ld-wp.template-help.com/wordpress_62312//wp-content/uploads/2017/02/main-slider-img1.jpg"
                                     data-sizes="(max-aspect-ratio: 1689/800) 178vh" data-width="1689"
                                     data-height="800"
                                     sizes="(max-aspect-ratio: 1689/800) 178vh"
                                     src="https://ld-wp.template-help.com/wordpress_62312//wp-content/uploads/2017/02/main-slider-img1.jpg"
                                />
                                <div className="uk-position-center uk-overlay uk-text-center uk-margin"
                                     data-uk-scrollspy="target: > *; cls: uk-animation-slide-bottom; delay: false;"
                                >
                                    <hr className="uk-divider-small"/>
                                    <div className="uk-text-lead uk-margin-remove-adjacent">JOIN THE INCREDIBLE</div>
                                    <h1 className="uk-heading-hero">World of Dance!</h1>
                                    <p><Link className="uk-button uk-button-text uk-text-uppercase" to="/">View the
                                        classes</Link></p>
                                </div>
                            </li>
                            <li>
                                <img className="uk-cover"
                                     alt=""
                                     data-uk-cover
                                     data-uk-img="target: !.uk-slideshow-items"
                                     data-src="https://ld-wp.template-help.com/wordpress_62312//wp-content/uploads/2017/02/main-slider-img2.jpg"
                                     data-sizes="(max-aspect-ratio: 1689/800) 178vh" data-width="1689"
                                     data-height="800"
                                     sizes="(max-aspect-ratio: 1689/800) 178vh"
                                     src="https://ld-wp.template-help.com/wordpress_62312//wp-content/uploads/2017/02/main-slider-img2.jpg"
                                />
                                <div className="uk-position-center uk-overlay uk-text-center uk-margin"
                                     data-uk-scrollspy="target: > *; cls: uk-animation-slide-bottom; delay: false;"
                                >
                                    <hr className="uk-divider-small"/>
                                    <div className="uk-text-lead uk-margin-remove-adjacent">JOIN THE INCREDIBLE</div>
                                    <h1 className="uk-heading-hero">World of Dance!</h1>
                                    <p><Link className="uk-button uk-button-text uk-text-uppercase" to="/">View the
                                        classes</Link></p>
                                </div>
                            </li>
                            <li>
                                <img className="uk-cover"
                                     alt=""
                                     data-uk-cover
                                     data-uk-img="target: !.uk-slideshow-items"
                                     data-src="https://ld-wp.template-help.com/wordpress_62312//wp-content/uploads/2017/02/main-slider-img3.jpg"
                                     data-sizes="(max-aspect-ratio: 1689/800) 178vh" data-width="1689"
                                     data-height="800"
                                     sizes="(max-aspect-ratio: 1689/800) 178vh"
                                     src="https://ld-wp.template-help.com/wordpress_62312//wp-content/uploads/2017/02/main-slider-img3.jpg"
                                />
                                <div className="uk-position-center uk-overlay uk-text-center uk-margin"
                                     data-uk-scrollspy="target: > *; cls: uk-animation-slide-bottom; delay: false;"
                                >
                                    <hr className="uk-divider-small"/>
                                    <div className="uk-text-lead uk-margin-remove-adjacent">JOIN THE INCREDIBLE</div>
                                    <h1 className="uk-heading-hero">World of Dance!</h1>
                                    <p><Link className="uk-button uk-button-text uk-text-uppercase" to="/">View the
                                        classes</Link></p>
                                </div>
                            </li>
                        </ul>
                        <div className="uk-visible@m uk-slidenav-container uk-position-bottom-left">
                            <button className="uk-slidenav-large uk-slidenav-previous uk-icon uk-slidenav"
                                    data-uk-slidenav-previous
                                    data-uk-slideshow-item="previous"
                                    data-uk-icon="arrow-left"
                                    data-ratio="2"
                            />
                            <button className="uk-slidenav-large uk-slidenav-next uk-icon uk-slidenav"
                                    data-uk-slidenav-next
                                    data-uk-slideshow-item="next"
                                    data-uk-icon="arrow-right"
                                    data-ratio="2"
                            />
                        </div>
                        <div className="uk-position-bottom-center uk-position-medium uk-visible@m">
                            <ul className="uk-slideshow-nav uk-dotnav uk-flex-center" data-uk-margin/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>