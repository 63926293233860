import React from 'react';

export default () =>
    <div>
        <div className="uk-section-secondary uk-preserve-color uk-section uk-padding-remove-bottom">
            <div className="uk-margin-large uk-container uk-container-large">
                <div className="uk-grid-large uk-grid" data-uk-grid>
                    <div className="uk-width-expand@m uk-grid-item-match uk-flex-middle uk-light uk-first-column">
                        <div className="uk-panel">
                            <h2 className="uk-width-xlarge">Um auf dem neuesten Stand zu bleiben, abboniere unseren Newsletter für News und Events</h2>
                        </div>
                    </div>
                    <div className="uk-width-expand@m uk-grid-item-match uk-flex-middle uk-light">
                        <div className="uk-panel">
                            <div className="uk-width-xlarge">
                                <form className="uk-form uk-panel js-form-newsletter" method="post" action="">
                                    <div className="uk-child-width-1-1 uk-grid uk-grid-stack" data-uk-grid>
                                        <div className="uk-position-relative uk-first-column">
                                            <button className="uk-form-icon uk-form-icon-flip uk-icon"
                                                    data-uk-icon="icon: arrow-right;"
                                                    title="Subscribe"/>
                                            <input className="uk-input" type="email" name="email"
                                                   placeholder="Deine E-Mail-Adresse" required/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="uk-grid-collapse uk-grid" data-uk-grid>
                <div className="uk-width-expand@m uk-width-1-2@s uk-first-column"/>
                <div className="uk-width-expand@m uk-grid-item-match uk-width-1-2@s">
                    <div className="uk-tile-default uk-tile">
                        <div className="uk-margin-small uk-width-medium@m uk-margin-auto">
                            <img alt="Tanzschule Fischer"
                                 data-src="/assets/logo.svg"
                                 data-uk-img
                                 src="/assets/logo.svg"
                            />
                        </div>
                        <div className="uk-margin-remove-top uk-width-medium@m uk-margin-auto uk-text-small uk-text-uppercase uk-text-center">
                            Hier tanzt du richtig.
                        </div>
                    </div>
                </div>
                <div className="uk-width-expand@m uk-grid-item-match uk-width-1-2@s">
                    <div className="uk-tile-default uk-tile">
                        <h3 className="uk-margin-small uk-width-medium@m uk-margin-auto uk-h5">Adresse</h3>
                        <hr className="uk-margin uk-margin-remove-top uk-width-medium@m uk-margin-auto"/>
                        <div className="uk-width-medium@m uk-margin-auto">
                            Haslacher Straße 5<br/>87435 Kempten
                        </div>
                    </div>
                </div>
                <div className="uk-width-expand@m uk-grid-item-match uk-width-1-2@s">
                    <div className="uk-tile-default uk-tile">
                        <h3 className="uk-margin-small uk-width-medium@m uk-margin-auto uk-h5">Social</h3>
                        <hr className="uk-margin uk-margin-remove-top uk-width-medium@m uk-margin-auto"/>
                        <ul className="uk-width-medium@m uk-margin-auto uk-list">
                            <li>
                                <div>
                                    <button className="uk-button uk-button-text" data-uk-scroll>Facebook</button>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <button className="uk-button uk-button-text" data-uk-scroll>Twitter</button>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <button className="uk-button uk-button-text" data-uk-scroll>Instagram</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>