import React from 'react';

export default () =>
    <div className="uk-section-default uk-section">
        <div className="uk-container uk-container-large">
            <h2 className="uk-margin-remove-vertical uk-width-xlarge uk-h1"
                data-uk-scrollspy-class
            >
                Unsere Leistungen
            </h2>
            <div className="uk-grid-margin">
                <div data-uk-grid className="uk-grid-stack">
                    <div className="uk-width-1-1@m uk-first-column">
                        <div className="uk-margin uk-text-left">
                            <div className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-large uk-grid"
                                 data-uk-grid="parallax: 200;"
                            >
                                <div className="uk-first-column">
                                    <div className="uk-inline-clip">
                                        <img alt=""
                                             data-src="https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-01-5c0adc77.webp"
                                             data-srcset="/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-01-433028b2.webp 629w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-01-1705a321.webp 768w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-01-dc51c85d.webp 1024w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-01-5c0adc77.webp 1103w"
                                             data-sizes="(min-width: 629px) 629px"
                                             data-width="1103"
                                             data-height="1600"
                                             data-uk-img
                                        />
                                        <div
                                            className="uk-overlay uk-padding-small uk-position-bottom-left uk-tile-default">
                                            <h3 className="uk-margin uk-margin-remove-adjacent uk-margin-small-bottom">
                                                Standartkurse
                                            </h3>
                                            <div className="uk-margin uk-text-muted">
                                                für Erwachsene
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-inline-clip">
                                        <img alt=""
                                             data-src="https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-02-b2e597d6.webp"
                                             data-srcset="https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-02-b2e597d6.webp 629w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-02-8c9f19ce.webp 768w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-02-701f6333.webp 1024w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-02-f0447719.webp 1103w"
                                             data-sizes="(min-width: 629px) 629px"
                                             data-width="1103"
                                             data-height="1600"
                                             data-uk-img
                                        />
                                        <div
                                            className="uk-overlay uk-padding-small uk-position-bottom-left uk-tile-default">
                                            <h3 className="uk-margin uk-margin-remove-adjacent uk-margin-small-bottom">Hip Hop</h3>
                                            <div className="uk-margin uk-text-muted">für Erwachsene</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-grid-margin uk-first-column">
                                    <div className="uk-inline-clip">
                                        <img alt=""
                                             data-src="https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-03-e256fd0a.webp"
                                             data-srcset="https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-03-e256fd0a.webp 629w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-03-4cc67254.webp 768w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-03-142505e9.webp 1024w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-03-947e11c3.webp 1103w"
                                             data-sizes="(min-width: 629px) 629px"
                                             data-width="1103"
                                             data-height="1600"
                                             data-uk-img
                                        />
                                        <div
                                            className="uk-overlay uk-padding-small uk-position-bottom-left uk-tile-default">
                                            <h3 className="uk-margin uk-margin-remove-adjacent uk-margin-small-bottom">
                                                Tanzkreise
                                            </h3>
                                            <div className="uk-margin uk-text-muted">
                                                ab Gold
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-grid-margin">
                                    <div className="uk-inline-clip">
                                        <img alt=""
                                             data-src="https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-04-8a3fef5f.webp"
                                             data-srcset="https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-04-8a3fef5f.webp 629w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-04-60db6a51.webp 768w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-04-f3f333ae.webp 1024w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-04-73a82784.webp 1103w"
                                             data-sizes="(min-width: 629px) 629px"
                                             data-width="1103"
                                             data-height="1600"
                                             data-uk-img
                                        />
                                        <div
                                            className="uk-overlay uk-padding-small uk-position-bottom-left uk-tile-default">
                                            <h3 className="uk-margin uk-margin-remove-adjacent uk-margin-small-bottom">
                                                Kangatraining
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-grid-margin uk-first-column">
                                    <div className="uk-inline-clip">
                                        <img alt=""
                                             data-src="https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-05-da8c8583.webp"
                                             data-srcset="https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-05-da8c8583.webp 629w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-05-a08201cb.webp 768w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-05-97c95574.webp 1024w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-05-1792415e.webp 1103w"
                                             data-sizes="(min-width: 629px) 629px"
                                             data-width="1103"
                                             data-height="1600"
                                             data-uk-img
                                        />
                                        <div
                                            className="uk-overlay uk-padding-small uk-position-bottom-left uk-tile-default">
                                            <h3 className="uk-margin uk-margin-remove-adjacent uk-margin-small-bottom">
                                                Poly Dance
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-grid-margin">
                                    <div className="uk-inline-clip">
                                        <img alt=""
                                             data-src="https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-06-2b593ae7.webp"
                                             data-srcset="https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-06-2b593ae7.webp 629w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-06-3b18bb24.webp 768w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-06-3b87fe1a.webp 1024w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-06-bbdcea30.webp 1103w"
                                             data-sizes="(min-width: 629px) 629px"
                                             data-width="1103"
                                             data-height="1600"
                                             data-uk-img
                                        />
                                        <div
                                            className="uk-overlay uk-padding-small uk-position-bottom-left uk-tile-default">
                                            <h3 className="uk-margin uk-margin-remove-adjacent uk-margin-small-bottom">
                                                Hochzeitskurse
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-grid-margin uk-first-column">
                                    <div className="uk-inline-clip">
                                        <img alt=""
                                             data-src="https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-07-7bea503b.webp"
                                             data-srcset="https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-07-7bea503b.webp 629w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-07-fb41d0be.webp 768w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-07-5fbd98c0.webp 1024w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-07-dfe68cea.webp 1103w"
                                             data-sizes="(min-width: 629px) 629px"
                                             data-width="1103"
                                             data-height="1600"
                                             data-uk-img
                                        />
                                        <div
                                            className="uk-overlay uk-padding-small uk-position-bottom-left uk-tile-default">
                                            <h3 className="uk-margin uk-margin-remove-adjacent uk-margin-small-bottom">
                                                Kinderkurse
                                            </h3>
                                            <div className="uk-margin uk-text-muted">ab 6 Jahren</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-grid-margin">
                                    <div className="uk-inline-clip">
                                        <img alt=""
                                             data-src="https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-08-fb8b1e4d.webp"
                                             data-srcset="https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-08-fb8b1e4d.webp 629w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-08-63228b2e.webp 768w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-08-2f5a94d5.webp 1024w, https://demo.yootheme.com/themes/joomla/2018/nioh-studio/templates/yootheme/cache/home-services-08-af0180ff.webp 1103w"
                                             data-sizes="(min-width: 629px) 629px"
                                             data-width="1103"
                                             data-height="1600"
                                             data-uk-img
                                        />
                                        <div
                                            className="uk-overlay uk-padding-small uk-position-bottom-left uk-tile-default">
                                            <h3 className="uk-margin uk-margin-remove-adjacent uk-margin-small-bottom">
                                                Jugendkurse
                                            </h3>
                                            <div className="uk-margin uk-text-muted">ab 12 Jahren</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>