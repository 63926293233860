import React from 'react';
import {Link} from "react-router-dom";

export default ({match: {url}}) =>
    <div className="uk-visible@l"
         data-uk-header>
        <div data-uk-sticky
             data-media="@l"
             data-cls-active="uk-navbar-sticky"
             data-sel-target=".uk-navbar-container"
             className="uk-sticky uk-sticky-fixed"
        >
            <div className="uk-navbar-container">
                <div className="uk-container uk-container-expand uk-padding-remove-left">
                    <nav className="uk-navbar"
                         data-uk-navbar="{'align':'left', 'boundary':'!.uk-navbar-container'}">
                        <div className="uk-navbar-left">
                            <Link to="/" className="uk-navbar-item uk-logo">
                                <img src="/assets/logo.svg" alt="Tanzschule Fischer"/>
                                <img src="/assets/logo-inverted.svg"
                                     className="uk-logo-inverse"
                                     alt="Tanzschule Fischer"/>
                            </Link>
                        </div>
                        <div className="uk-navbar-center">
                            <ul className="uk-navbar-nav">
                                <li className={url === '/' ? 'uk-active' : ''}>
                                    <Link to="/">Home</Link>
                                </li>
                                <li className={url === '/courses' ? 'uk-active' : ''}>
                                    <Link to="/courses">Kurse</Link>
                                </li>
                                <li className={url === '/coaches' ? 'uk-active' : ''}>
                                    <Link to="/coaches">Team</Link>
                                </li>
                                <li className={url === '/about' ? 'uk-active' : ''}>
                                    <Link to="/about">Über uns</Link>
                                </li>
                                <li className={url === '/schedules' ? 'uk-active' : ''}>
                                    <Link to="/schedules">Termine</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="uk-navbar-right">
                            <div className="uk-navbar-item">
                                <div className="custom">
                                    <a className="uk-link-text" href="tel:+4983121020" rel="noopener noreferrer">
                                        <img width="14"
                                             height="28"
                                             alt="receiver-icon"
                                             data-uk-icon="icon:receiver"
                                        /> (0831) - 210 20
                                    </a>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>