import React from 'react';
import {Link} from "react-router-dom";

export default () =>
    <div className="uk-section-default uk-light" style={{'background': "brown"}}
         data-uk-scrollspy="target: [uk-scrollspy-class]; cls: uk-animation-slide-left-medium; delay: 200;">
        <div data-src="https://demo.yootheme.com/themes/joomla/2018/yard/images/yootheme/home-about-bg.svg"
             data-uk-img
             className="uk-background-norepeat uk-background-top-right uk-background-image@s uk-section"
             data-uk-parallax="bgx: -80,-80;bgy: 80,400"
        >
            <div className="uk-container uk-container-large">
                <div className="uk-grid-large uk-grid-margin-large"
                     data-uk-grid
                >
                    <div className="uk-width-xlarge@l">
                        <h2 className="uk-margin-remove-vertical uk-h1"
                            data-uk-scrollspy-class
                        >
                            Über uns
                        </h2>
                        <div
                            className="uk-margin-large uk-width-xlarge"
                            data-uk-scrollspy-clas
                        >
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                            Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis
                            parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu,
                            pretium quis, sem.
                        </div>
                        <div className="uk-margin"
                             data-uk-scrollspy-class
                        >
                            <Link className="uk-button uk-button-default uk-button-large" to="/">Mehr erfahren</Link>
                        </div>
                    </div>
                    <div className="uk-width-expand@l">
                        <div className="uk-margin">
                            <div className="uk-child-width-1-2 uk-grid-match" data-uk-grid>
                                <div className="uk-margin-large-top">
                                    <div className="uk-panel"
                                         data-uk-scrollspy-class="uk-animation-slide-left-medium"
                                    >
                                        <img alt=""
                                             data-src="https://demo.yootheme.com/themes/joomla/2018/yard/templates/yootheme/cache/home-about-01-03086070.webp"
                                             data-uk-img sizes="(min-width: 530px) 530px"
                                        />
                                        <h3 className="uk-margin uk-h6">
                                            <span className="uk-text-primary">01</span> - Unser Team
                                        </h3>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-panel"
                                         data-uk-scrollspy-class="uk-animation-slide-left-medium"
                                    >
                                        <img alt=""
                                             data-src="https://demo.yootheme.com/themes/joomla/2018/yard/templates/yootheme/cache/home-about-02-af46cb1e.webp"
                                             data-uk-img sizes="(min-width: 530px) 530px"
                                        />
                                        <h3 className="uk-margin uk-h6">
                                            <span className="uk-text-primary">02</span> - Unsere Tanzschule in Kempten
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>