import React from 'react';
import Layout from '../components/Layout'
import SlideShow from '../components/Slideshow'
import About from '../components/About'
import Services from '../components/Services'
import Courses from '../components/Courses'
import Coaches from '../components/Coaches'
import Classes from '../components/Classes'
import CircleCounter from '../components/CircleCounter'

export default props =>
    <Layout {...props}>
        <SlideShow/>
            <Courses/>
            <About/>
        <Services/>
        <Coaches/>
        {/*<Classes/>*/}
        <CircleCounter/>
    </Layout>