import React, {Component} from 'react';

export default class extends Component {

    constructor(props) {
        super(props);
        this.arr = new Array(6);
    }

    render() {
        return <div className="uk-section-default uk-section"
                    data-uk-scrollspy="target: [data-uk-scrollspy-class]; cls: uk-animation-slide-left-small; delay: false;"
        >
            <div className="uk-container uk-container-large">
                <div data-uk-slider="autoplay: 1;"
                     data-uk-scrollspy-class="uk-animation-slide-left-medium"
                >
                    <div className="uk-position-relative">
                        <ul className="uk-slider-items uk-child-width-1-2@s uk-child-width-1-4@m uk-grid">
                            {Array.apply(null, {length: 6}).map((el,i) => {
                                    return <li key={i}>
                                        <div className="uk-card uk-card-default uk-border-rounded">
                                            <div className="uk-card-media-top">
                                                <img src="https://getuikit.com/docs/images/photo3.jpg" alt=""/>
                                            </div>
                                            <div className="uk-card-body uk-text-center">
                                                <h3 className="uk-heading-primary">{Math.floor(Math.random() * (2000 - 100)) + 100}</h3>
                                                <p>Gewonnene Preise</p>
                                            </div>
                                        </div>
                                    </li>
                                }
                            )}
                        </ul>
                        <div className="uk-visible@s uk-slidenav-container uk-position-top-right">
                            <button className="uk-slidenav-previous uk-icon uk-slidenav"
                                    data-uk-slidenav-previous="true"
                                    data-uk-slider-item="previous"
                                    data-uk-icon="arrow-left"
                                    data-ratio="2"
                            />
                            <button className="uk-slidenav-next uk-icon uk-slidenav"
                                    data-uk-slidenav-next="true"
                                    data-uk-slider-item="next"
                                    data-uk-icon="arrow-right"
                                    data-ratio="2"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}
