import React from 'react';

export default () =>
    <div className="uk-section-default uk-light" style={{'background': "brown"}}
         data-uk-scrollspy="target: [uk-scrollspy-class]; cls: uk-animation-slide-left-small; delay: false;"
    >
        <div data-src="https://demo.yootheme.com/themes/joomla/2018/yard/images/yootheme/home-residences-bg.svg"
             data-uk-img
             className="uk-background-norepeat uk-background-contain uk-background-top-left uk-background-image@m uk-section"
        >
            <div className="uk-container uk-container-large">
                <div className="uk-grid-large uk-grid-margin-large uk-grid" data-uk-grid>
                    <div className="uk-width-2-3@l uk-first-column">
                        <div className="uk-margin uk-slider uk-slider-container"
                             data-uk-slider="autoplay: 1;" data-uk-scrollspy-class="uk-animation-slide-left-medium">
                            <div className="uk-position-relative">
                                <ul className="uk-slider-items uk-grid">
                                    <li className="uk-width-1-1 uk-width-1-2@s">
                                        <div className="uk-cover-container uk-transition-toggle" tabIndex="0">
                                            <img alt=""
                                                 data-uk-img="target: !.uk-slider-items"
                                                 data-src="https://ld-wp.template-help.com/wordpress_62312/wp-content/uploads/2017/02/coach-1.jpg"
                                                 data-width="345"
                                                 data-height="345"
                                            />
                                            <div
                                                className="uk-panel uk-padding-small uk-position-bottom-left uk-transition-slide-bottom-small uk-transition-slide-bottom-small">
                                                <h3 className="uk-margin uk-h5">Shape Tower</h3>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="uk-width-1-1 uk-width-1-2@s">
                                        <div className="uk-cover-container uk-transition-toggle" tabIndex="0">
                                            <img alt=""
                                                 data-uk-img="target: !.uk-slider-items"
                                                 data-src="https://ld-wp.template-help.com/wordpress_62312/wp-content/uploads/2017/02/coach-2.jpg"
                                                 data-width="345"
                                                 data-height="345"
                                            />
                                            <div
                                                className="uk-panel uk-padding-small uk-position-bottom-left uk-transition-slide-bottom-small uk-transition-slide-bottom-small">
                                                <h3 className="uk-margin uk-h5">Alison Park</h3>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="uk-width-1-1 uk-width-1-2@s">
                                        <div className="uk-cover-container uk-transition-toggle" tabIndex="0">
                                            <img alt=""
                                                 data-uk-img="target: !.uk-slider-items"
                                                 data-src="https://ld-wp.template-help.com/wordpress_62312/wp-content/uploads/2017/02/coach-3.jpg"
                                                 data-width="345"
                                                 data-height="345"
                                            />
                                            <div
                                                className="uk-panel uk-padding-small uk-position-bottom-left uk-transition-slide-bottom-small uk-transition-slide-bottom-small">
                                                <h3 className="uk-margin uk-h5">The Spice Tower</h3>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="uk-width-1-1 uk-width-1-2@s">
                                        <div className="uk-cover-container uk-transition-toggle" tabIndex="0">
                                            <img alt=""
                                                 data-uk-img="target: !.uk-slider-items"
                                                 data-src="https://ld-wp.template-help.com/wordpress_62312/wp-content/uploads/2017/02/coach-2.jpg"
                                                 data-width="345"
                                                 data-height="345"
                                            />
                                            <div
                                                className="uk-panel uk-padding-small uk-position-bottom-left uk-transition-slide-bottom-small uk-transition-slide-bottom-small">
                                                <h3 className="uk-margin uk-h5">Alison Park</h3>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div
                                    className="uk-visible@s uk-slidenav-container uk-position-top-right">
                                    <button className="uk-slidenav-previous uk-icon uk-slidenav ukte"
                                       data-uk-slidenav-previous
                                       data-uk-slider-item="previous"
                                       data-uk-icon="arrow-left"
                                       data-ratio="2"
                                    />
                                    <button className="uk-slidenav-next uk-icon uk-slidenav ukte"
                                       data-uk-slidenav-next
                                       data-uk-slider-item="next"
                                       data-uk-icon="arrow-right"
                                       data-ratio="2"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-expand@l">
                        <h2 className="uk-margin-remove-vertical uk-width-large uk-h1" data-uk-scrollspy-class>
                            Trainieren mit<br className="uk-visible@xl"/> den Besten
                        </h2>
                        <div className="uk-margin-large"
                             data-uk-scrollspy-class
                        >
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>